const DocMenuConfig = [
  {
    pages: [
      {
        heading: "Tableau de bord",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Déclarations",
        route: "/declarations",
        svgIcon: "media/icons/duotune/general/gen005.svg",
        fontIcon: "bi-archive",
      },
      {
        heading: "Nouvelle déclaration",
        route: "/declarations/new",
        svgIcon: "media/icons/duotune/general/gen005.svg",
        fontIcon: "bi-archive",
      },
      // {
      //   heading: "layoutBuilder",
      //   route: "/builder",
      //   svgIcon: "media/icons/duotune/general/gen019.svg",
      //    fontIcon: "bi-layers",
      // }
    ],
  },
  {
    heading: "MANAGEMENT",
    pages: [
      {
        heading: "Responsables Techniques",
        route: "/responsables-techniques",
        svgIcon: "media/icons/duotune/general/gen049.svg"
      }
    ],
  },
];

export default DocMenuConfig;
