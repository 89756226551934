
import { defineComponent, getCurrentInstance, onMounted, ref } from "vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTNotificationsMenuCustom from "@/layout/header/partials/NotificationsMenuCustom.vue";
import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { number } from "yup/lib/locale";

export default defineComponent({
  name: "topbar",
  components: {
    KTNotificationsMenu,
    KTQuickLinksMenu,
    KTUserMenu,
    KTNotificationsMenuCustom
  },
  setup() {
    const emitter = getCurrentInstance()?.appContext.config.globalProperties.emitter;
    const nbrNotifications = ref<number>(0);

    onMounted(() => {
      emitter.on('refresh-notifications-number', (data: any) => {
        nbrNotifications.value = data.total;
      });
    });

    return {
      nbrNotifications
    }
  }
});
