
import { defineComponent, getCurrentInstance, onBeforeMount, ref } from "vue";
import { Actions, Mutations } from "@/store/enums/BackStoreEnum";
import { useStore } from "vuex";
import { useDate } from "@/composables/useDate";

export default defineComponent({
  name: "notifications-menu-custom",
  components: {},
  setup() {
    const emitter = getCurrentInstance()?.appContext.config.globalProperties.emitter;
    const store = useStore();
    const notifications = ref([]);
    const {formatToDuration} = useDate();

    const getNotifications = () => {
      store.dispatch(Actions.GET_DECLA_BY_PERM_BY_STATUT, "A_MODIFIER")
        .then((res) => {
          notifications.value = res;
          emitter.emit('refresh-notifications-number', {total: notifications.value.length});          
        });
    }

    onBeforeMount(() => {
      getNotifications();
    })

    return {
      notifications,
      formatToDuration
    };
  },
});
