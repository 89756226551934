export function useDate() {

    const formatToDuration = (date) => {
        
        // JavaScript program to illustrate 
        // calculation of no. of days between two date 
        
        // To set two dates to two variables
        var date1 = new Date(date);
        var date2 = new Date();
        var duration = 0;
        
        // To calculate the time difference of two dates
        var Difference_In_Time = date2.getTime() - date1.getTime();
        
        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

        //on convertit en heure
        if(Difference_In_Days < 1){
            duration = Difference_In_Days * 24;
            if(parseInt(duration as any) < 1){
                return duration * 60
            }
            return `${parseInt(duration as any)} hr`
        }
        
        //On convertit en jour
        if(Difference_In_Days > 1 && Difference_In_Days < 8){
            return `${parseInt(Difference_In_Days as any)} j`
        }

        //on convertit en semaine
        if(Difference_In_Days >= 8 && Difference_In_Days <= 30){
            return `${parseInt((Difference_In_Days/7) as any)} sem`
        }
        
        return `${date1.getUTCMonth()+1}/${date1.getUTCFullYear()}` ;
    }

    return {
        formatToDuration
    }


}